/**
 * @name: 分账管理-分账记录接口文件
 * @author:
 * @date: 2024-05-13 11:37
 * @description： 分账管理-分账记录接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ILedgerRecordParams, ILedgerRecordRes } from "./types";

export const ledgerRecordPageApi = (params: ILedgerRecordParams) => get<ILedgerRecordRes>('/admin/cents/record/query', params)

export const ledgerRecordExportApi = (params: ILedgerRecordParams) => get('/admin/cents/record/export', params, 'blob')
