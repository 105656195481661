
/**
 * @name: 分账管理-分账记录
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 分账管理-分账记录
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  ledgerRecordPageApi,
  ledgerRecordExportApi
} from '@/apis/ledger/record'
import {
  ILedgerRecordParams,
  ILedgerRecord
} from '@/apis/ledger/record/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class ledgerRecord extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ILedgerRecord[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ILedgerRecordParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        "label": "关联订单ID",
        "prop": "orderId",
        "align": "left",
        "width": 180,
        "search": true
      },
      {
        label: '商品名称',
        prop: 'productName',
        search: true,
        align: 'center',
        overHidden: true
      },
      {
        label: '规格属性',
        prop: 'skuName',
        align: 'center',
        overHidden: true,
        slot: true
      },
      {
        "label": "商品数量",
        "prop": "payQty",
        "align": "center"
      },
      {
        "label": "分账总额（元）",
        "prop": "centsPrice",
        "align": "center"
      },
      {
        "label": "平台抽佣（元）",
        "prop": "platformPrice",
        "align": "center",
        "slot": true,
        width: 150,
        overHidden: true
      },
      {
        "label": "政府基金A（元）",
        "prop": "govAPrice",
        "align": "center",
        "slot": true,
        width: 150,
        overHidden: true
      },
      {
        "label": "政府基金B（元）",
        "prop": "govBPrice",
        "align": "center",
        "slot": true,
        width: 150,
        overHidden: true
      },
      {
        "label": "贡献池（元）",
        "prop": "contributionPoolPrice",
        "align": "center",
        "slot": true,
        width: 150,
        overHidden: true
      },
      {
        "label": "分账时间",
        "prop": "centsTime",
        "align": "center",
        "type": "daterange",
        "search": true
      }
    ]
  }

  centsPrice = '0.00'

  contributionPoolPrice = '0.00'

  govAPrice = '0.00'

  govBPrice = '0.00'

  platformPrice = '0.00'

  getList () {
    this.tableLoading = true

    const query: ILedgerRecordParams = deepCopy(this.queryParam)

    if (query.centsTime && query.centsTime.length) {
      query.centsStartTime = query.centsTime[0]
      query.centsEndTime = query.centsTime[1]
    } else {
      query.centsStartTime = ''
      query.centsEndTime = ''
    }
    delete query.centsTime

    ledgerRecordPageApi(query).then(e => {
      this.centsPrice = e.centsPrice;
      this.platformPrice = e.platformPrice;
      this.govAPrice = e.govAPrice
      this.govBPrice = e.govBPrice
      this.contributionPoolPrice = e.contributionPoolPrice
      this.tableData = e.page.list
      this.tableTotal = e.page.total
      this.tableLoading = false
    })
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];
    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }
    return json.map(item => item.value).join(';')
  }

  exportExcel () {
    const query: ILedgerRecordParams = deepCopy(this.queryParam)

    if (query.centsTime && query.centsTime.length) {
      query.centsStartTime = query.centsTime[0]
      query.centsEndTime = query.centsTime[1]
    } else {
      query.centsStartTime = ''
      query.centsEndTime = ''
    }
    delete query.centsTime

    ledgerRecordExportApi(query).then(e => {
      exportFile(e, "分账记录.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
